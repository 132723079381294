import { useContext } from 'react';
import { PageDataContext } from 'components/Contexts';
import { PageDataType } from 'types/types';

const usePageData = (): PageDataType => {
  const { pageData } = useContext(PageDataContext);

  const latestBatch =
    pageData?.latestBatchTracingDate && pageData?.latestTraceableRawMaterials
      ? {
          date: pageData.latestBatchTracingDate,
          rawMaterials: pageData.latestTraceableRawMaterials,
        }
      : undefined;

  return { ...pageData, latestBatch };
};

export default usePageData;
